import React from "react";
import WinnersList from "../components/home/winnerslist";

import Seo from "../components/seo/index";

import Layout from "../layout/index";
const Winner = () => {
  return (
    <>
      <Seo title="Green Heartfulness Run 2024 - Winners" />
      <Layout isSticky>
        <WinnersList />
      </Layout>
    </>
  );
};

export default Winner;
