import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../assets/styles/winner.scss";

const tableData = {
  halfMarathon: {
    female30Above: [
      {
        position: "1",
        athelete: "Kitu Sheoran",
        age: "35",
        bib_no: "21127",
        split3_5km: "00:22:24",
        split4_5km: "00:30:41",
        split17_5km: "01:48:19",
        pace: "06:04",
        gunTime: "02:08:31",
        chipTime: "02:08:09",
      },
      {
        position: "2",
        athelete: "Aditya Devi  M",
        age: "44",
        bib_no: "21068",
        split3_5km: "00:19:42",
        split4_5km: "00:27:54",
        split17_5km: "01:46:42",
        pace: "06:05",
        gunTime: "02:08:44",
        chipTime: "02:08:39",
      },
      {
        position: "3",
        athelete: "Syamala Devi Peri",
        age: "49",
        bib_no: "21051",
        split3_5km: "00:20:33",
        split4_5km: "00:28:56",
        split17_5km: "01:46:21",
        pace: "06:08",
        gunTime: "02:13:00",
        chipTime: "02:09:38",
      },
    ],
    female30Below: [
      {
        position: "1",
        athelete: "Keesari. Keerthana",
        age: "20",
        bib_no: "21303",
        split3_5km: "00:22:52",
        split4_5km: "00:31:31",
        split17_5km: "01:56:57",
        pace: "06:47",
        gunTime: "02:23:40",
        chipTime: "02:23:16",
      },
      {
        position: "2",
        athelete: "Kothakapeta Poojitha",
        age: "20",
        bib_no: "21305",
        split3_5km: "00:22:05",
        split4_5km: "00:30:19",
        split17_5km: "01:56:59",
        pace: "06:48",
        gunTime: "02:24:10",
        chipTime: "02:23:44",
      },
      {
        position: "3",
        athelete: "Banala. Ganga",
        age: "19",
        bib_no: "21304",
        split3_5km: "00:22:57",
        split4_5km: "00:31:01",
        split17_5km: "01:58:35",
        pace: "06:57",
        gunTime: "02:27:10",
        chipTime: "02:26:46",
      },
    ],
    male30Above: [
      {
        position: "1",
        athelete: "Jagan  Reddy",
        age: "46",
        bib_no: "21294",
        split3_5km: "00:14:38",
        split4_5km: "00:20:21",
        split17_5km: "01:10:10",
        pace: "04:04",
        gunTime: "01:26:42",
        chipTime: "01:25:52",
      },
      {
        position: "2",
        athelete: "RAVIVARMA SWAERO",
        age: "34",
        bib_no: "21278",
        split3_5km: "00:15:34",
        split4_5km: "00:22:24",
        split17_5km: "01:24:13",
        pace: "04:51",
        gunTime: "01:42:26",
        chipTime: "01:42:22",
      },
      {
        position: "3",
        athelete: "SUNIL AGRAWAL",
        age: "49",
        bib_no: "21311",
        split3_5km: "00:16:48",
        split4_5km: "00:23:24",
        split17_5km: "01:25:04",
        pace: "04:52",
        gunTime: "01:43:02",
        chipTime: "01:42:45",
      },
    ],
    male30Below: [
      {
        position: "1",
        athelete: "Rahul Turan",
        age: "27",
        bib_no: "21337",
        split3_5km: "00:14:48",
        split4_5km: "00:20:30",
        split17_5km: "01:10:39",
        pace: "04:01",
        gunTime: "01:25:01",
        chipTime: "01:24:55",
      },
      {
        position: "2",
        athelete: "DAYYALA  SHIVAKUMAR",
        age: "19",
        bib_no: "21291",
        split3_5km: "00:13:55",
        split4_5km: "00:19:37",
        split17_5km: "01:14:46",
        pace: "04:26",
        gunTime: "01:34:11",
        chipTime: "01:33:34",
      },
      {
        position: "3",
        athelete: "Raviteja Nerellapally",
        age: "21",
        bib_no: "21171",
        split3_5km: "00:15:11",
        split4_5km: "00:21:31",
        split17_5km: "01:21:34",
        pace: "04:40",
        gunTime: "01:49:06",
        chipTime: "01:38:41",
      },
    ],
  },
  tenKMarathon: {
    female30Above: [
      {
        position: "1",
        athelete: "Lalitha  W",
        age: "38",
        bib_no: "10618",
        split3_5km: "00:23:52",
        split4_5km: "00:34:08",
        pace: "05:29",
        gunTime: "00:59:44",
        chipTime: "00:54:45",
      },
      {
        position: "2",
        athelete: "Meghna Lowalekar",
        age: "36",
        bib_no: "10145",
        split3_5km: "00:24:16",
        split4_5km: "00:34:41",
        pace: "05:43",
        gunTime: "00:57:46",
        chipTime: "00:57:11",
      },
      {
        position: "3",
        athelete: "Suma S",
        age: "40",
        bib_no: "10523",
        split3_5km: "00:26:11",
        split4_5km: "00:37:06",
        pace: "05:50",
        gunTime: "01:00:04",
        chipTime: "00:58:21",
      },
    ],
    female30Below: [
      {
        position: "1",
        athelete: "Renu Yadav",
        age: "25",
        bib_no: "10465",
        split3_5km: "00:17:19",
        split4_5km: "00:24:20",
        pace: "05:18",
        gunTime: "00:53:32",
        chipTime: "00:53:04",
      },
      {
        position: "2",
        athelete: "Pooja K",
        age: "29",
        bib_no: "10247",
        split3_5km: "00:24:31",
        split4_5km: "00:34:46",
        pace: "05:49",
        gunTime: "00:59:16",
        chipTime: "00:58:06",
      },
      {
        position: "3",
        athelete: "Palvai. Akhila",
        age: "19",
        bib_no: "10586",
        split3_5km: "00:19:16",
        split4_5km: "00:27:14",
        pace: "05:51",
        gunTime: "00:58:49",
        chipTime: "00:58:34",
      },
    ],
    male30Above: [
      {
        position: "1",
        athelete: "Razaak Shaik",
        age: "36",
        bib_no: "10652",
        split3_5km: "00:13:52",
        split4_5km: "00:19:34",
        pace: "04:26",
        gunTime: "00:44:33",
        chipTime: "00:44:23",
      },
      {
        position: "2",
        athelete: "Santosh Mallareddi",
        age: "33",
        bib_no: "10146",
        split3_5km: "00:19:55",
        split4_5km: "00:28:29",
        pace: "05:04",
        gunTime: "00:56:43",
        chipTime: "00:50:36",
      },
      {
        position: "3",
        athelete: "Ranbir Singh  Bagga",
        age: "45",
        bib_no: "10600",
        split3_5km: "00:16:58",
        split4_5km: "00:24:00",
        pace: "05:04",
        gunTime: "00:52:21",
        chipTime: "00:50:42",
      },
    ],
    male30Below: [
      {
        position: "1",
        athelete: "Kiran  Deekonda",
        age: "28",
        bib_no: "10193",
        split3_5km: "00:13:37",
        split4_5km: "00:18:58",
        pace: "04:21",
        gunTime: "00:44:25",
        chipTime: "00:43:32",
      },
      {
        position: "2",
        athelete: "Suraj Aiyappa BT",
        age: "20",
        bib_no: "10592",
        split3_5km: "00:16:03",
        split4_5km: "00:22:11",
        pace: "04:30",
        gunTime: "00:45:11",
        chipTime: "00:44:58",
      },
      {
        position: "3",
        athelete: "Manjinder Singh",
        age: "24",
        bib_no: "10590",
        split3_5km: "00:16:06",
        split4_5km: "00:22:13",
        pace: "04:30",
        gunTime: "00:45:11",
        chipTime: "00:45:01",
      },
    ],
  },
  fiveKMarathon: {
    female30Above: [
      {
        position: "1",
        athelete: "Rajeshwari Subramaniam",
        age: "64",
        bib_no: "6055",
        split3_5km: "00:16:22",
        pace: "06:36",
        gunTime: "00:45:43",
        chipTime: "00:33:01",
      },
      {
        position: "2",
        athelete: "Meenal Agarwal",
        age: "38",
        bib_no: "5196",
        split3_5km: "00:22:10",
        pace: "06:47",
        gunTime: "00:36:32",
        chipTime: "00:33:56",
      },
      {
        position: "3",
        athelete: "Leah Rich",
        age: "45",
        bib_no: "5646",
        split3_5km: "00:22:12",
        pace: "07:00",
        gunTime: "00:35:57",
        chipTime: "00:35:02",
      },
    ],
    female30Below: [
      {
        position: "1",
        athelete: "Begari  Manasa",
        age: "18",
        bib_no: "5916",
        split3_5km: "00:18:21",
        pace: "05:57",
        gunTime: "00:29:53",
        chipTime: "00:29:49",
      },
      {
        position: "2",
        athelete: "Nukala. Srivani",
        age: "19",
        bib_no: "5917",
        split3_5km: "00:20:15",
        pace: "06:25",
        gunTime: "00:32:09",
        chipTime: "00:32:05",
      },
      {
        position: "3",
        athelete: "Boddu. Jhansi",
        age: "18",
        bib_no: "5918",
        split3_5km: "00:20:57",
        pace: "06:37",
        gunTime: "00:33:14",
        chipTime: "00:33:08",
      },
    ],
    male30Above: [
      {
        position: "1",
        athelete: "Dr Raghavendra Babu Y",
        age: "48",
        bib_no: "5571",
        split3_5km: "00:11:01",
        pace: "04:27",
        gunTime: "00:38:28",
        chipTime: "00:22:19",
      },
      {
        position: "2",
        athelete: "AMBEDKAR  NUKABATTINI",
        age: "40",
        bib_no: "6020",
        split3_5km: "00:14:43",
        pace: "04:31",
        gunTime: "00:22:47",
        chipTime: "00:22:39",
      },
      {
        position: "3",
        athelete: "Ram Babu",
        age: "49",
        bib_no: "6083",
        split3_5km: "00:16:30",
        pace: "05:07",
        gunTime: "00:25:45",
        chipTime: "00:25:37",
      },
    ],
    male30Below: [
      {
        position: "1",
        athelete: "Robin Singh",
        age: "20",
        bib_no: "5931",
        split3_5km: "00:14:15",
        pace: "04:27",
        gunTime: "00:22:30",
        chipTime: "00:22:15",
      },
      {
        position: "2",
        athelete: "Shankar Y",
        age: "11",
        bib_no: "5572",
        split3_5km: "00:11:05",
        pace: "04:27",
        gunTime: "00:38:22",
        chipTime: "00:22:17",
      },
      {
        position: "3",
        athelete: "Raul",
        age: "22",
        bib_no: "5938",
        split3_5km: "00:14:25",
        pace: "04:30",
        gunTime: "00:22:45",
        chipTime: "00:22:30",
      },
    ],
  },
};

const WinnersList = () => {
  const [key, setKey] = useState("21K");
  return (
    <>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2 className="text-center mb-3 section-title">Winners List</h2>
          </div>
        </div>
      </div>
      <div className="row" style={{ padding: "10px 40px" }}>
        <div className="col-md-12">
          <p className="selfie-text">
            Looking for Individual Timings & Certificate,{" "}
            <a
              href="https://results.sporthive.com/events/7036361152449031936"
              target="_blank"
            >
              click Here
            </a>
          </p>
        </div>
      </div>
      <div style={{ padding: "10px 40px" }}>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
          style={{ justifyContent: "start" }}
        >
          <Tab eventKey="21K" title="21 KM  Category">
            <h5>21 KM (female) 30 & above</h5>
            <div className="tree-detail-table-wrapper">
              <table className="tree-detail-table">
                <tr className="table-row">
                  <th className="position-cell">Position</th>
                  <th className="name-cell">Athelete</th>

                  <th className="head-cell">Age</th>
                  <th className="head-cell">BIB#</th>
                  <th className="head-cell">3.5 km</th>
                  <th className="head-cell">4.5 km</th>
                  <th className="head-cell">17.5 km</th>
                  <th className="head-cell">Pace</th>
                  <th className="head-cell">Gun Time</th>
                  <th className="head-cell">Chip Time</th>
                </tr>
                {tableData.halfMarathon.female30Above.map((item) => {
                  return (
                    <tr className="table-row">
                      <td className="body-cell">
                        <span>{item.position}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.athelete}</span>{" "}
                      </td>

                      <td className="body-cell">
                        <span>{item.age}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.bib_no}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split3_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.split4_5km}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split17_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.pace}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.gunTime}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.chipTime}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <h5 className="mt-6">21 KM (female) Under 30</h5>
            <div className="tree-detail-table-wrapper">
              <table className="tree-detail-table">
                <tr className="table-row">
                  <th className="position-cell">Position</th>
                  <th className="name-cell">Athelete</th>

                  <th className="head-cell">Age</th>
                  <th className="head-cell">BIB#</th>
                  <th className="head-cell">3.5 km</th>
                  <th className="head-cell">4.5 km</th>
                  <th className="head-cell">17.5 km</th>
                  <th className="head-cell">Pace</th>
                  <th className="head-cell">Gun Time</th>
                  <th className="head-cell">Chip Time</th>
                </tr>
                {tableData.halfMarathon.female30Below.map((item) => {
                  return (
                    <tr className="table-row">
                      <td className="body-cell">
                        <span>{item.position}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.athelete}</span>{" "}
                      </td>

                      <td className="body-cell">
                        <span>{item.age}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.bib_no}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split3_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.split4_5km}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split17_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.pace}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.gunTime}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.chipTime}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <h5 className="mt-6">21 KM (male) 30 & above</h5>
            <div className="tree-detail-table-wrapper">
              <table className="tree-detail-table">
                <tr className="table-row">
                  <th className="position-cell">Position</th>
                  <th className="name-cell">Athelete</th>

                  <th className="head-cell">Age</th>
                  <th className="head-cell">BIB#</th>
                  <th className="head-cell">3.5 km</th>
                  <th className="head-cell">4.5 km</th>
                  <th className="head-cell">17.5 km</th>
                  <th className="head-cell">Pace</th>
                  <th className="head-cell">Gun Time</th>
                  <th className="head-cell">Chip Time</th>
                </tr>
                {tableData.halfMarathon.male30Above.map((item) => {
                  return (
                    <tr className="table-row">
                      <td className="body-cell">
                        <span>{item.position}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.athelete}</span>{" "}
                      </td>

                      <td className="body-cell">
                        <span>{item.age}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.bib_no}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split3_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.split4_5km}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split17_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.pace}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.gunTime}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.chipTime}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <h5 className="mt-6">21 KM (male) Under 30</h5>
            <div className="tree-detail-table-wrapper">
              <table className="tree-detail-table">
                <tr className="table-row">
                  <th className="position-cell">Position</th>
                  <th className="name-cell">Athelete</th>

                  <th className="head-cell">Age</th>
                  <th className="head-cell">BIB#</th>
                  <th className="head-cell">3.5 km</th>
                  <th className="head-cell">4.5 km</th>
                  <th className="head-cell">17.5 km</th>
                  <th className="head-cell">Pace</th>
                  <th className="head-cell">Gun Time</th>
                  <th className="head-cell">Chip Time</th>
                </tr>
                {tableData.halfMarathon.male30Below.map((item) => {
                  return (
                    <tr className="table-row">
                      <td className="body-cell">
                        <span>{item.position}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.athelete}</span>{" "}
                      </td>

                      <td className="body-cell">
                        <span>{item.age}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.bib_no}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split3_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.split4_5km}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split17_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.pace}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.gunTime}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.chipTime}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </Tab>
          <Tab eventKey="10K" title="10 KM  Category">
            <h5>10 KM (female) 30 & above</h5>
            <div className="tree-detail-table-wrapper">
              <table className="tree-detail-table">
                <tr className="table-row">
                  <th className="position-cell">Position</th>
                  <th className="name-cell">Athelete</th>

                  <th className="head-cell">Age</th>
                  <th className="head-cell">BIB#</th>
                  <th className="head-cell">3.5 km</th>
                  <th className="head-cell">4.5 km</th>
                  <th className="head-cell">Pace</th>
                  <th className="head-cell">Gun Time</th>
                  <th className="head-cell">Chip Time</th>
                </tr>
                {tableData.tenKMarathon.female30Above.map((item) => {
                  return (
                    <tr className="table-row">
                      <td className="body-cell">
                        <span>{item.position}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.athelete}</span>{" "}
                      </td>

                      <td className="body-cell">
                        <span>{item.age}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.bib_no}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split3_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.split4_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.pace}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.gunTime}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.chipTime}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <h5 className="mt-6">10 KM (female) Under 30</h5>
            <div className="tree-detail-table-wrapper">
              <table className="tree-detail-table">
                <tr className="table-row">
                  <th className="position-cell">Position</th>
                  <th className="name-cell">Athelete</th>

                  <th className="head-cell">Age</th>
                  <th className="head-cell">BIB#</th>
                  <th className="head-cell">3.5 km</th>
                  <th className="head-cell">4.5 km</th>
                  <th className="head-cell">Pace</th>
                  <th className="head-cell">Gun Time</th>
                  <th className="head-cell">Chip Time</th>
                </tr>
                {tableData.tenKMarathon.female30Below.map((item) => {
                  return (
                    <tr className="table-row">
                      <td className="body-cell">
                        <span>{item.position}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.athelete}</span>{" "}
                      </td>

                      <td className="body-cell">
                        <span>{item.age}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.bib_no}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split3_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.split4_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.pace}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.gunTime}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.chipTime}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <h5 className="mt-6">10 KM (male) 30 & above</h5>
            <div className="tree-detail-table-wrapper">
              <table className="tree-detail-table">
                <tr className="table-row">
                  <th className="position-cell">Position</th>
                  <th className="name-cell">Athelete</th>

                  <th className="head-cell">Age</th>
                  <th className="head-cell">BIB#</th>
                  <th className="head-cell">3.5 km</th>
                  <th className="head-cell">4.5 km</th>
                  <th className="head-cell">Pace</th>
                  <th className="head-cell">Gun Time</th>
                  <th className="head-cell">Chip Time</th>
                </tr>
                {tableData.tenKMarathon.male30Above.map((item) => {
                  return (
                    <tr className="table-row">
                      <td className="body-cell">
                        <span>{item.position}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.athelete}</span>{" "}
                      </td>

                      <td className="body-cell">
                        <span>{item.age}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.bib_no}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split3_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.split4_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.pace}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.gunTime}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.chipTime}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <h5 className="mt-6">10 KM (male) Under 30</h5>
            <div className="tree-detail-table-wrapper">
              <table className="tree-detail-table">
                <tr className="table-row">
                  <th className="position-cell">Position</th>
                  <th className="name-cell">Athelete</th>

                  <th className="head-cell">Age</th>
                  <th className="head-cell">BIB#</th>
                  <th className="head-cell">3.5 km</th>
                  <th className="head-cell">4.5 km</th>
                  <th className="head-cell">Pace</th>
                  <th className="head-cell">Gun Time</th>
                  <th className="head-cell">Chip Time</th>
                </tr>
                {tableData.tenKMarathon.male30Below.map((item) => {
                  return (
                    <tr className="table-row">
                      <td className="body-cell">
                        <span>{item.position}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.athelete}</span>{" "}
                      </td>

                      <td className="body-cell">
                        <span>{item.age}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.bib_no}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split3_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.split4_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.pace}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.gunTime}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.chipTime}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </Tab>
          <Tab eventKey="5K" title="5 KM Category">
            <h5>5 KM (female) 30 & above</h5>
            <div className="tree-detail-table-wrapper">
              <table className="tree-detail-table">
                <tr className="table-row">
                  <th className="position-cell">Position</th>
                  <th className="name-cell">Athelete</th>

                  <th className="head-cell">Age</th>
                  <th className="head-cell">BIB#</th>
                  <th className="head-cell">3.5 km</th>
                  <th className="head-cell">Pace</th>
                  <th className="head-cell">Gun Time</th>
                  <th className="head-cell">Chip Time</th>
                </tr>
                {tableData.fiveKMarathon.female30Above.map((item) => {
                  return (
                    <tr className="table-row">
                      <td className="body-cell">
                        <span>{item.position}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.athelete}</span>{" "}
                      </td>

                      <td className="body-cell">
                        <span>{item.age}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.bib_no}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split3_5km}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.pace}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.gunTime}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.chipTime}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <h5 className="mt-6">5 KM (female) Under 30</h5>
            <div className="tree-detail-table-wrapper">
              <table className="tree-detail-table">
                <tr className="table-row">
                  <th className="position-cell">Position</th>
                  <th className="name-cell">Athelete</th>

                  <th className="head-cell">Age</th>
                  <th className="head-cell">BIB#</th>
                  <th className="head-cell">3.5 km</th>
                  <th className="head-cell">Pace</th>
                  <th className="head-cell">Gun Time</th>
                  <th className="head-cell">Chip Time</th>
                </tr>
                {tableData.fiveKMarathon.female30Below.map((item) => {
                  return (
                    <tr className="table-row">
                      <td className="body-cell">
                        <span>{item.position}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.athelete}</span>{" "}
                      </td>

                      <td className="body-cell">
                        <span>{item.age}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.bib_no}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split3_5km}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.pace}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.gunTime}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.chipTime}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <h5 className="mt-6">5 KM (male) 30 & above</h5>
            <div className="tree-detail-table-wrapper">
              <table className="tree-detail-table">
                <tr className="table-row">
                  <th className="position-cell">Position</th>
                  <th className="name-cell">Athelete</th>

                  <th className="head-cell">Age</th>
                  <th className="head-cell">BIB#</th>
                  <th className="head-cell">3.5 km</th>
                  <th className="head-cell">Pace</th>
                  <th className="head-cell">Gun Time</th>
                  <th className="head-cell">Chip Time</th>
                </tr>
                {tableData.fiveKMarathon.male30Above.map((item) => {
                  return (
                    <tr className="table-row">
                      <td className="body-cell">
                        <span>{item.position}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.athelete}</span>{" "}
                      </td>

                      <td className="body-cell">
                        <span>{item.age}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.bib_no}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split3_5km}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.pace}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.gunTime}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.chipTime}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <h5 className="mt-6">5 KM (male) Under 30</h5>
            <div className="tree-detail-table-wrapper">
              <table className="tree-detail-table">
                <tr className="table-row">
                  <th className="position-cell">Position</th>
                  <th className="name-cell">Athelete</th>

                  <th className="head-cell">Age</th>
                  <th className="head-cell">BIB#</th>
                  <th className="head-cell">3.5 km</th>
                  <th className="head-cell">Pace</th>
                  <th className="head-cell">Gun Time</th>
                  <th className="head-cell">Chip Time</th>
                </tr>
                {tableData.fiveKMarathon.male30Below.map((item) => {
                  return (
                    <tr className="table-row">
                      <td className="body-cell">
                        <span>{item.position}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.athelete}</span>{" "}
                      </td>

                      <td className="body-cell">
                        <span>{item.age}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.bib_no}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.split3_5km}</span>
                      </td>

                      <td className="body-cell">
                        <span>{item.pace}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.gunTime}</span>
                      </td>
                      <td className="body-cell">
                        <span>{item.chipTime}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default WinnersList;
